import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";
import Key from "views/auth/Key";
// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";

export default function Auth() {
  return (
    <>
      <Navbar transparent />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">

          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
        
          ></div>

          
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Route path="/auth/key" exact component={Key} />
            <Redirect from="/auth" to="/auth/key" />
          </Switch>
          
          <FooterSmall absolute />
        </section>
      </main>

    </>
  );
}
