import {React, useState} from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import api from "components/Api/Api";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Key() {

  
  const [chave, setChave] = useState('') ;
  const [toast_try, setToast] = useState(false);
  const [Auth, setAuth] = useState(false);
  const [toast_error, setToast_error] = useState(false);


 const component =() =>{
  return(
    <div>
    {

    toast.warn('Por favor retire todos os caracteres especiais!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    onClose: handleChange_toast
    })
    }
    <ToastContainer />
    </div>
  )
  }

  
  const handleChange_toast = () =>{

    setToast(false)
    setToast_error(false)

    
  }
 

  

  const handleChange = event => {
    
    const specialChars =
    // eslint-disable-next-line
      /[`!#%^&*()+\=\[\]{};':"\\|,.<>\/?~]/; // sobrou o underline e o cifrão e o arroba
  
    if(specialChars.test(event.target.value)){
      
      setToast(true)

    }else if(!specialChars.test(event.target.value)){

      setChave(event.target.value)

      console.log(chave)
      console.log(toast_error)


    }
  
    
    
  }



  const handleSubmit = event => {
  
 

      event.preventDefault();
    
    
      const parse = JSON.stringify({chave: chave});
    
      const options = {
        headers: {
        'Content-Type': 'application/json'
        }
    
      }
      api.post("app/client/auth-key", parse, options)
      .then(res => {
    
        setAuth(true)
        
      
        
      }).catch(err => { 
       
       
        setToast_error(true)
        
      })
    
      
    }

    const component_error = () => {
      return(
        <div>
        {
    
      toast.error('Chave inválida!', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      onClose: handleChange_toast
      })
        }
        <ToastContainer />
        </div>
      )
    }
    
   
    return (
      <>

     {
      toast_try ? component() : null
     }


    {
      toast_error ? component_error() : null
     }

     
     {
      Auth ?     <Switch>
      
      <Redirect to="/auth/login" />
    </Switch> : null

     }
      <div className="container mx-auto px-4 h-full">
          
          
          <div className="flex content-center items-center justify-center h-full" >
            
            <div className="w-full lg:w-4/12 px-4" >
              
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
                <div className="rounded-t mb-0 px-6 py-6" >
                  
  
                  <div className="flex content-center items-center justify-center h-full">
                      <img
                        alt="..."
                        className="shadow-xl rounded-full h-auto align-middle border-none  bg-blueGray-800  relative bottom-0 top-0 w max-w-150-px"
                        src={require("assets/img/smart.png").default}
                      />
                      
                  </div>

              
                 
                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <div className="text-blueGray-400 text-center mb-3 font-bold">
                    <small>Insira suas credênciais</small>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="relative w-full mb-3">
                      
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Chave
                      </label>
                      
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Chave"
                        onChange={handleChange}
                      />
                      
                    </div>
  
                    <div>
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          id="customCheckLogin"
                          type="checkbox"
                          className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        />
                        <span className="ml-2 text-sm font-semibold text-blueGray-600">
                          Lembrar Chave
                        </span>
                      </label>
                    </div>
                    

                    <div className="text-center mt-6">
                    
                      <button
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Entrar
                      </button>
                     
                    </div>
                  </form>
                </div>
              </div>
        
              <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
           
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Problemas com a Chave?</small>
                </Link>
              </div>
            </div>
            
            </div>
          </div>
        </div>


      
         
      </>
    );
  }



  