import {React, useState} from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import api from "components/Api/Api";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [toast_try, setToast] = useState(false);
  const [Auth, setAuth] = useState(false);
  const [toast_error, setToast_error] = useState(false);

  const component =() =>{
    return(
      <div>
      {
  
      toast.warn('Por favor retire todos os caracteres especiais!', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      onClose: handleChange_toast
      })
      }
      <ToastContainer />
      </div>
    )
    
  
  }


  const handleChange_toast = () =>{

    setToast(false)
    setToast_error(false)

    
  }

  const handleChange = event => {
    
    const specialChars =
    // eslint-disable-next-line
      /[`!#%^&*()+\=\[\]{};':"\\|,<>\/?~]/; // sobrou o underline e o cifrão e o arroba
  
    if(specialChars.test(event.target.value)){
      
      setToast(true)

    }else if(!specialChars.test(event.target.value)){

      setEmail(event.target.value);


     console.log(email)
     
      console.log(toast_error)


    }
  
    
    
  }

  const handleChange_Password = event => {
    
    const specialChars =
    // eslint-disable-next-line
      /[`!#%^&*()+\=\[\]{};':"\\|,<>\/?~]/; // sobrou o underline e o cifrão e o arroba
  
    if(specialChars.test(event.target.value)){
      
      setToast(true)

    }else if(!specialChars.test(event.target.value)){

      setPassword(event.target.value);

      console.log(password)
      console.log(toast_error)


    }
  
    
    
  }

    const component_error = () => {
      return(
        <div>
        {
    
      toast.error('Senha ou login inválidos!', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      onClose: handleChange_toast
      })
        }
        <ToastContainer />
        </div>
      )
    }

  const handleSubmit = event => {
  
 

    event.preventDefault();
  
  
    const parse = JSON.stringify({user: email, senha: password});
  
    const options = {
      headers: {
      'Content-Type': 'application/json',
      'DB' : 'cidade_perecife'
      }
  
    }
    api.post("app/user-action/login/", parse, options)
    .then(res => {
  
      setAuth(true)

      console.log(res)
      
    
      
    }).catch(err => { 
     
     
      setToast_error(true)
      
    })
  
    
  }
  
 
  return (
    <>
      {
      toast_try ? component() : null
     }


    {
      toast_error ? component_error() : null
     }

     
     {
      Auth ?     <Switch>
      
      <Redirect to="/admin/dashboard" />
    </Switch> : null

     }

      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full" >
          
          <div className="w-full lg:w-4/12 px-4" >
            
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
              <div className="rounded-t mb-0 px-6 py-6" >
                

                <div className="flex content-center items-center justify-center h-full">

                  <div className=" lg:w-6/12 xl:w-4/12 px-4">
          
                    <img
                      alt="..."
                      className="w-20 mr-1"
                      src={require("assets/img/private-account-icon.svg").default}
                    />
                    
                  </div>

                </div>
               
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Insira suas credênciais</small>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Senha
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Senha"
                      onChange={handleChange_Password}
                    />
                  </div>
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Lembre de mim
                      </span>
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Entrar
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200"
                >
                  <small>Esqueceu sua senha?</small>
                </a>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Criar nova conta</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </>
  );
}
